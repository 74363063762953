
#tickr-box {
    background: #C0C0C0;
    /*    background: #fff;*/
    max-width: 100%;
    padding: 3px;
    margin: 0 auto 0 auto;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    cursor: pointer;
}

.tickr-title {
    color: #FFFFFF;
    font: bold 18px arial, sans-serif;
    padding: 5px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

#tickr-scroll ul {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    list-style: none;
}

#tickr-scroll {
    background: transparent;
    height: 27px;
    margin: 3px auto 0 auto;
    overflow: hidden;
    padding: 0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

#tickr-scroll li {
    color: #000000;
    font: normal 18px arial, sans-serif;
    line-height: 26px;
}

#tickr-scroll a {
    color: #0066FF;
    text-decoration: none;
}

#tickr-scroll a:hover {
    color: #3399FF;
    text-decoration: none;
}

#tickr-scroll ul {
    padding: 0 6px 0 6px;
    -webkit-animation: tickr 12s cubic-bezier(1, 0, .5, 0) infinite;
    -moz-animation: tickr 12s cubic-bezier(1, 0, .5, 0) infinite;
    -ms-animation: tickr 12s cubic-bezier(1, 0, .5, 0) infinite;
    animation: tickr 12s cubic-bezier(1, 0, .5, 0) infinite;
}

#tickr-scroll ul:hover {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -ms-animation-play-state: paused;
    animation-play-state: paused;
}

@-webkit-keyframes tickr {
    0% {
        margin-top: 0;
    }
    25% {
        margin-top: -26px;
    }
    50% {
        margin-top: -52px;
    }
    75% {
        margin-top: -78px;
    }
    100% {
        margin-top: 0;
    }
}

@-moz-keyframes tickr {
    0% {
        margin-top: 0;
    }
    25% {
        margin-top: -26px;
    }
    50% {
        margin-top: -52px;
    }
    75% {
        margin-top: -78px;
    }
    100% {
        margin-top: 0;
    }
}

@-ms-keyframes tickr {
    0% {
        margin-top: 0;
    }
    25% {
        margin-top: -26px;
    }
    50% {
        margin-top: -52px;
    }
    75% {
        margin-top: -78px;
    }
    100% {
        margin-top: 0;
    }
}

@keyframes tickr {
    0% {
        margin-top: 0;
    }
    25% {
        margin-top: -26px;
    }
    50% {
        margin-top: -52px;
    }
    75% {
        margin-top: -78px;
    }
    100% {
        margin-top: 0;
    }
}

@media handheld and (max-width: 482px), screen and (max-device-width: 482px), screen and (max-width: 482px) {

    .tickr-title {
        font-size: 14px;
    }

    #tickr-scroll li {
        font-size: 12px;
    }

}