
.profile-navol, .profile-info{
  margin-top:30px;   
}

.profile-navol .user-heading {
  background: #fbc02d;
  color: #fff;
  border-radius: 4px 4px 0 0;
  -webkit-border-radius: 4px 4px 0 0;
  padding: 30px;
  text-align: center;
}

.profile-navol .user-heading.round .profillee  {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  border: 10px solid rgba(255,255,255,0.3);
  display: inline-block;
}

.profile-navol .user-heading .profillee img {
  width: 112px;
  height: 112px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
}

.profile-navol .user-heading h1 {
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 5px;
}

.profile-navol .user-heading p {
  font-size: 12px;
}

.profile-navol ul {
  margin-top: 1px;
}

.profile-navol ul > li {
  border-bottom: 1px solid #ebeae6;
  margin-top: 0;
  line-height: 30px;
}

.profile-navol ul > li:last-child {
  border-bottom: none;
}

.profile-navol ul > li > .profillee {
  border-radius: 0;
  -webkit-border-radius: 0;
  color: #89817f;
  border-left: 5px solid #fff;
}

.profile-navol ul > li > a:hover, .profile-navol ul > li > a:focus, .profile-navol ul li.active  a {
  background: #f8f7f5 !important;
  border-left: 5px solid #fbc02d;
  color: #89817f !important;
}

.profile-navol ul > li:last-child > .profillee:last-child {
  border-radius: 0 0 4px 4px;
  -webkit-border-radius: 0 0 4px 4px;
}

.profile-navol ul > li > .profillee > i{
  font-size: 16px;
  padding-right: 10px;
  color: #bcb3aa;
}

.r-activity {
  margin: 6px 0 0;
  font-size: 12px;
}


.p-text-area, .p-text-area:focus {
  border: none;
  font-weight: 300;
  box-shadow: none;
  color: #c3c3c3;
  font-size: 16px;
}

.profile-info .panel-footer {
  background-color:#f8f7f5 ;
  border-top: 1px solid #e7ebee;
}

.profile-info .panel-footer ul li a {
  color: #7a7a7a;
}

.bio-graph-heading {
  background: #fbc02d;
  color: #fff;
  text-align: center;
  font-style: italic;
  padding: 40px 110px;
  border-radius: 4px 4px 0 0;
  -webkit-border-radius: 4px 4px 0 0;
  font-size: 16px;
  font-weight: 300;
}

.bio-graph-info {
  color: #89817e;
}

.bio-graph-info h1 {
  font-size: 22px;
  font-weight: 300;
  margin: 0 0 20px;
}

.bio-row {
  width: 50%;
  float: left;
  margin-bottom: 10px;
  padding:0 15px;
}

.bio-row p span {
  width: 100px;
  display: inline-block;
}

.bio-chart, .bio-desk {
  float: left;
}

.bio-chart {
  width: 40%;
}

.bio-desk {
  width: 60%;
}

.bio-desk h4 {
  font-size: 15px;
  font-weight:400;
}

.bio-desk h4.terques {
  color: #4CC5CD;
}

.bio-desk h4.red {
  color: #e26b7f;
}

.bio-desk h4.green {
  color: #97be4b;
}

.bio-desk h4.purple {
  color: #caa3da;
}

.file-pos {
  margin: 6px 0 10px 0;
}

.profile-activity h5 {
  font-weight: 300;
  margin-top: 0;
  color: #c3c3c3;
}

.summary-head {
  background: #ee7272;
  color: #fff;
  text-align: center;
  border-bottom: 1px solid #ee7272;
}

.summary-head h4 {
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.summary-head p {
  color: rgba(255,255,255,0.6);
}

ul.summary-list {
  display: inline-block;
  padding-left:0 ;
  width: 100%;
  margin-bottom: 0;
}

ul.summary-list > li {
  display: inline-block;
  width: 19.5%;
  text-align: center;
}

ul.summary-list > li > .profillee > i {
  display:block;
  font-size: 18px;
  padding-bottom: 5px;
}

ul.summary-list > li > .profillee {
  padding: 10px 0;
  display: inline-block;
  color: #818181;
}

ul.summary-list > li  {
  border-right: 1px solid #eaeaea;
}

ul.summary-list > li:last-child  {
  border-right: none;
}

.activity {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}

.activity.alt {
  width: 100%;
  float: right;
  margin-bottom: 10px;
}

.activity span {
  float: left;
}

.activity.alt span {
  float: right;
}
.activity span, .activity.alt span {
  width: 45px;
  height: 45px;
  line-height: 45px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  background: #eee;
  text-align: center;
  color: #fff;
  font-size: 16px;
}

.activity.terques span {
  background: #8dd7d6;
}

.activity.terques h4 {
  color: #8dd7d6;
}
.activity.purple span {
  background: #b984dc;
}

.activity.purple h4 {
  color: #b984dc;
}
.activity.blue span {
  background: #90b4e6;
}

.activity.blue h4 {
  color: #90b4e6;
}
.activity.green span {
  background: #aec785;
}

.activity.green h4 {
  color: #aec785;
}

.activity h4 {
  margin-top:0 ;
  font-size: 16px;
}

.activity p {
  margin-bottom: 0;
  font-size: 13px;
}

.activity .activity-desk i, .activity.alt .activity-desk i {
  float: left;
  font-size: 18px;
  margin-right: 10px;
  color: #bebebe;
}

.activity .activity-desk {
  margin-left: 70px;
  position: relative;
}

.activity.alt .activity-desk {
  margin-right: 70px;
  position: relative;
}

.activity.alt .activity-desk .panel {
  float: right;
  position: relative;
}

.activity-desk .panel {
  background: #F4F4F4 ;
  display: inline-block;
}


.activity .activity-desk .arrow {
  border-right: 8px solid #F4F4F4 !important;
}
.activity .activity-desk .arrow {
  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
  display: block;
  height: 0;
  left: -7px;
  position: absolute;
  top: 13px;
  width: 0;
}

.activity-desk .arrow-alt {
  border-left: 8px solid #F4F4F4 !important;
}

.activity-desk .arrow-alt {
  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
  display: block;
  height: 0;
  right: -7px;
  position: absolute;
  top: 13px;
  width: 0;
}

.activity-desk .album {
  display: inline-block;
  margin-top: 10px;
}

.activity-desk .album a{
  margin-right: 10px;
}

.activity-desk .album a:last-child{
  margin-right: 0px;
}



































/* menu on the personal-information page */
/* CSS */
.button-78 {
  align-items: center;
  appearance: none;
  background-clip: padding-box;
  background-color: initial;
  background-image: none;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  font-family: Eina01,sans-serif;
  font-size: 16px;
  font-weight: 800;
  justify-content: center;
  line-height: 24px;
  margin: 0;
  min-height: 64px;
  outline: none;
  overflow: visible;
  padding: 19px 26px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: 50%;
  word-break: keep-all;
  z-index: 0;
  flex: 1;
  border-radius: 2px !important;
  height: 120px;
  color: #fff !important;
}

@media (min-width: 768px) {
  .button-78 {
    padding: 19px 32px;
  }
}

.button-78:before,
.button-78:after {
  border-radius: 10px;
}

.button-78:before {
  background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
}

.button-78:after {
  background-color: initial;
  background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
  bottom: 4px;
  content: "";
  display: block;
  left: 4px;
  overflow: hidden;
  position: absolute;
  right: 4px;
  top: 4px;
  transition: all 100ms ease-out;
  z-index: -1;
}

.button-78:hover:not(:disabled):before {
  background: linear-gradient(92.83deg, rgb(255, 116, 38) 0%, rgb(249, 58, 19) 100%);
}

.button-78:hover:not(:disabled):after {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition-timing-function: ease-in;
  opacity: 0;
}

.button-78:active:not(:disabled) {
  color: #ccc;
}

.button-78:active:not(:disabled):before {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
}

.button-78:active:not(:disabled):after {
  background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
  bottom: 4px;
  left: 4px;
  right: 4px;
  top: 4px;
}

.button-78:disabled {
  cursor: default;
  opacity: .24;
}










