:root {

  --glitter: url("https://assets.codepen.io/13471/silver-glitter-background.png");
  --duration: 6.66s;

}

.card-front:before {

  content: "";
  inset: 0;
  position: absolute;
  transform: translate3d(0, 0, 0.01px);

  background-image: var(--glitter), var(--glitter),
    linear-gradient(120deg, black 25%, white, black 75%);
  background-size: 100% 100%, 80% 80%, 200% 200%;
  background-blend-mode: multiply, multiply, overlay;
  background-position: 50% 50%, 50% 50%, 50% 50%;

  mix-blend-mode: color-dodge;
  filter: brightness(2) contrast(0.8);

  animation: bg var(--duration) ease infinite;

}

.card-front {

  display: grid;
  position: relative;
  transform: translate3d(0, 0, 0.01px);
  width: 90vw;
  max-width: 580px;
  aspect-ratio: 3/2;

  border-radius: 3.5% 3.5% 3.5% 3.5% / 5% 5% 5% 5%;

  background-size: cover;

  box-shadow: 0 30px 40px -25px rgba(15, 5, 20, 1), 0 20px 50px -15px rgba(15, 5, 20, 1);
  overflow: hidden;
  animation: tilt var(--duration) ease infinite;
  image-rendering: optimizequality;

}

.card-front:after {
  
  content: "";
  background: none, none, linear-gradient(125deg, rgba(255,255,255,0) 0%, rgba(255,255,255,.4) 0.1%, rgba(255,255,255,0) 60%);
  background-size: 200% 200%;
  mix-blend-mode: hard-light;
  animation: bg var(--duration) ease infinite;
  
}






.card-front * {

  font-family: PT Mono, monospace;

}

.cardLogo,
.expiry,
.name,
.number,
.chip,
.icon {

  color: #ccc;
  position: absolute;
  margin: 0;
  padding: 0;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  font-size: clamp(0.75rem, 2.8vw + 0.2rem, 1.1rem);
  inset: 5%;
  text-shadow: -1px -1px 0px rgba(255,255,255,0.5),1px -1px 0px rgba(255,255,255,0.5),1px 1px 0px rgba(0,0,0,0.5),1px -1px 0px rgba(0,0,0,0.5);
  z-index: 5;

}

.name, .number, .expiry {
  background-image: linear-gradient(to bottom, #ededed 20%, #bababa 70%), none,
    linear-gradient(120deg, transparent 10%, white 40%, white 60%, transparent 90%);
  background-size: cover, cover, 200%;
  background-position: 50% 50%;
  background-blend-mode: overlay;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  animation: bg var(--duration) ease infinite;
  
}

.number {

  font-family: PT Mono, monospace;
  text-align: center;
  font-size: clamp(1rem, 8vw - 0.5rem, 2.5rem);
  letter-spacing: 0.025em;
  top: 60%;
  bottom: auto;

}
.expiry,
.name {

  top: auto;

}

.name {

  right: auto;
  max-width: 180px;
  line-height: 1.2;
  text-align: left;

}

.expiry {

  left: auto;

}

.cardLogo {

  bottom: auto;
  left: auto;
  width: 15%;
  filter: invert(1) saturate(0) brightness(1) contrast(1.2);
  mix-blend-mode: screen;

}

.chip {

  display: grid;
  place-items: center;
  width: 14%;
  aspect-ratio: 5/4;
  left: 10%;
  top: 30%;
  border-radius: 10% 10% 10% 10% / 15% 15% 15% 15%;

  background-image: none, none,
    linear-gradient(120deg, #777 10%, #ddd 40%, #ddd 60%, #777 90%);
  background-size: 200% 200%;
  background-position: 50% 50%;

  overflow: hidden;
  animation: bg var(--duration) ease infinite;

}













.chip svg {

  display: block;
  width: 90%;
  fill: none;
  stroke: #444;
  stroke-width: 2;

}

.contactless {

  position: absolute;
  left: 23%;
  top: 30%;
  width: 12%;
  rotate: 90deg;

  stroke-width: 1.25;
  stroke: currentColor;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  opacity: 0.5;

}

.icon {

  width: 25%;
  bottom: auto;
  right: auto;
  top: 0;
  left: 15px;
  filter: invert(1) hue-rotate(180deg) saturate(5) contrast(2);

}


























@keyframes tilt {

  0%, 100% { transform: translate3d(0, 0, 0.01px) rotateY(-20deg) rotateX(5deg); }
  50% { transform: translate3d(0, 0, 0.01px) rotateY(20deg) rotateX(5deg); }

}

@keyframes bg {

  0%, 100% { background-position: 50% 50%, calc(50% + 1px) calc(50% + 1px), 0% 50%; }
  50% { background-position: 50% 50%, calc(50% - 1px) calc(50% - 1px), 100% 50%; }

}


main {

  display: grid;
  grid-template-rows: minmax(20px,100px) 1fr;
  place-items: center;
  min-height: 100%;
  perspective: 1000px;

}


#chip,
#contactless {

  display: none;

}

h1 {
  margin: 1em;
  color: white;
  opacity: 0.7;
  text-shadow: 0 1px 1px black;
}






/* button */


.btn {
  width: 130px;
  height: 40px;
  font-size: 1.1em;
  cursor: pointer;
  background: #fff !important;
  color: #000;
  border: none;
  border-radius: 5px;
  transition: all .4s;
  align-self: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
 }
 
 .btn:hover {
  border-radius: 5px;
  transform: translateY(-10px);
  box-shadow: 0 7px 0 -2px #f85959,
   0 15px 0 -4px #39a2db,
   0 16px 10px -3px #39a2db;
 }
 
 .btn:active {
  transition: all 0.2s;
  transform: translateY(-5px);
  box-shadow: 0 2px 0 -2px #f85959,
   0 8px 0 -4px #39a2db,
   0 12px 10px -3px #39a2db;
 }