


@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");
*,
*:after,
*:before {
  box-sizing: border-box;
}



.menu {
  padding: 10px;
  background-color: #070713;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 20px 20px 0 0;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.075);
}

.link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 50px;
  border-radius: 99em;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transform-origin: center left;
  transition: width 0.2s ease-in;
  text-decoration: none;
  color: inherit;
}


.link:before {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border-radius: 99em;
  width: 100%;
  height: 100%;
  top: 0;
  transform: translateX(100%);
  transition: transform 0.2s ease-in;
  transform-origin: center right;
  background-color: #eee;
  
}

 .link.maybeactive {
  outline: 0;
  width: 130px;
  color: #000;
}
.link.maybeactive:before {
  transform: translateX(0);
  opacity: 1;
}

.link-icon {
  width: 28px;
  height: 28px;
  display: block;
  flex-shrink: 0;
  left: 18px;
  position: absolute;
}
.link-icon svg {
  width: 28px;
  height: 28px;
}

.link-title, .link.maybeactive .link-title{
 
  transform-origin: center right;
  display: block;
  text-align: center;
  text-indent: 28px;
  width: 100%;
  color: #000 !important;
}